<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Hizmet Sağlayıcılar İçin Başvuru Formu</h2>
        <tabs-new-basvuru current="2" hide="6"></tabs-new-basvuru>
        <tabs-edevlet-basvuru-sub v-if="loggedIn" current="5" hide="6"></tabs-edevlet-basvuru-sub>
        <tabs-eimza-basvuru-sub v-else current="5" hide="6"></tabs-eimza-basvuru-sub>

        <div class="formhead">
          <div>
            <p>
              Bu sayfada ilettiğiniz bilgiler/belgeler listelenmektedir.
              Onaylamadan önce aşağıdaki bilgilerinizi kontrol edebilirsiniz.
              <br />Bir sonraki adımda gönderilecek olan,
              <b>başvuru numaranızın</b> yer
              aldığı e-posta'yı
              <b>lütfen saklayınız</b>.
            </p>
          </div>
        </div>

        <bilgi-boxes></bilgi-boxes>

        <div class="formpart formline" v-if="sorted_fileSet.length>0">
          <div class="formfull">
            <h3>Kullanım taahhütnamesi</h3>

            <div class="filerow filekt">
              <div v-for="(file, index) in sorted_fileSet" :key="index" class="fileline">
                <!-- <div class="filetype">{{ belge_turu_map[file.docType].str }}</div> -->
                <!-- <img src="@/assets/img/file_pdf.svg" alt /> -->
                <div>
                  <img class="fileimgkt"
                    :src="
                      require(`@/assets/img/file_${file.fileName
                        .split('.')
                        .pop()}.svg`)
                    "
                    alt
                  />
                  <div class="filename">{{ file.fileName }}</div>
                </div>
                <div class="verif">
                  <img src="@/assets/img/ico_tick.svg" alt />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="formpart formline">
          <div class="formfull">
            <h3>Marka Bilgileri</h3>

            <div v-if="application.brandSet && application.brandSet.length > 0">
              <div class="izintable" v-for="(item, index) in getBrands" :key="index">
                <div class>
                  <form>
                    <div class="izinline">
                      <marka-show :brand="item" :editButtons="false" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="formline">
          <div class="check">
            <label class="labelcontainer">
              <input type="checkbox" v-model="beyan_ok" />
              <span class="checkmark"></span>
            </label>
            <h5>
              Verdiğim tüm bilgilerin/belgelerin
              doğruluğunu beyan ederim.
            </h5>
          </div>
        </div>

        <sonraki-bar label="Onayla" @next="NEXT" :no-app-no="true" :friction="500" class="mb2" />

        <div class="formpart">
          <div class="formfull">
            <h3>Süreç nasıl ilerleyecek?</h3>

            <div class="desctext">
              <p>
                “Onayla” butonuyla başvurunuz hesap yöneticilerimize
                iletilecektir.
              </p>
              <p>
                Bilgileriniz/belgeleriniz hesap yöneticilerimiz
                tarafından kontrol edilerek bir değişiklik gerekmesi halinde
                belirttiğiniz iletişim kanalları üzerinden tarafınıza bildirim
                yapılacaktır.
              </p>
              <p>
                Bilgilerinizin/belgelerinizin doğruluğu sağlanana dek birkaç kez
                bildirim yapılabilir.
              </p>

              <p>
                Web sayfamızdaki ilgili bağlantıları takip ederek İleti Yönetim
                Sistemi hakkında detaylı bilgi edinebilir, Mevzuat ve Sıkça
                Sorulan Sorular bölümlerini inceleyebilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import MarkaShow from "./genel/MarkaShow";

import SonrakiBar from "./genel/SonrakiBar";
import BilgiBoxes from "./genel/BilgiBoxes";

export default {
  name: "HSBasvuruOnaylamaOnBasvuru",

  components: {
    BilgiBoxes,
    SonrakiBar,
     MarkaShow,
  },

  data() {
    return {
      beyan_ok: false,
      belge_turu_map: require("../../../misc/enums").ENUM_BELGE_TURU,
    };
  },

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapGetters("applicant", ["getBrands"]),

    sorted_fileSet() {
      return this.application.fileSet.sort((a, b) =>
        this.belge_turu_map[a.docType].order >
        this.belge_turu_map[b.docType].order
          ? 1
          : -1
      ).filter(e=>e.docType==="HIZMET_SOZLESMESI");
    },
  },

  methods: {
    ...mapActions("applicant", ["on_basvuru_onayla"]),

    ...mapMutations("applicant", ["SET_APPLICATION"]),

    NEXT() {
      if (!this.beyan_ok) {
        this.Alert(
          "Devam edebilmek için belirttiğiniz bilgilerinizin/belgelerinizin doğru olduğunu beyan etmeniz gereklidir."
        );
        return;
      }

      this.on_basvuru_onayla()
        .then((res) => {
          if (res.ok) {
            // _paq.push(["trackEvent", "Basvuru", "On_Basvuru_Onay", "Success"]);
            this.SET_APPLICATION(res.payload);
            // this.$router.push("/hizmet-saglayici/basvuru/sonuc-on-basvuru");
            this.$router.push("/hizmet-saglayici/basvuru/sonuc");
          }
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Basvuru", "On_Basvuru_Onay", "Error"]);
        });
    },
  },
};
</script>
